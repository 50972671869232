import React from 'react';
import { get, find, capitalize } from 'lodash';
import { Popup } from './Popup';
import { UiButton } from '../../ui';
import { GeMap } from '../../store/generated-api';
import { isActiveNow, openingHours } from '../../utils/time';
import { inject, observer } from 'mobx-react';
import { IStore } from '../../store/modules';
import { decOfNum } from '../../utils';
import DepositInfo from '../DepositInfo/DepositInfo';

interface Props {
  point: GeMap;
  show: boolean;
  onCloseModal: () => void;
  store?: IStore;
}

@inject('store')
@observer
export class PlaceInfoPopup extends React.Component<Props> {
  render() {
    const { point, show, onCloseModal, store } = this.props;
    const { profile, user, session } = store!;
    const dtype = Number(profile.data.dtype || user.chargingType);
    const countTypeAvailable = get(find(point.dev, { dtype }), 'dtype_count') || 0;
    const emptyCount = point.empty_count || 0;
    const getCharging = !session.sessionActive && countTypeAvailable > 0 && point.active;
    const getChargingLink = store!.user.isAuth ? '/get-charging' : '/sign-in';

    return (
      <Popup show={show} onCloseModal={onCloseModal}>
        <h3 className="subtitle mb-8">{`${point.location_address}, ${point.location_name}`}</h3>
        <p className="textGray-40">{point.location_guide}</p>
        <p className="textGray-40">
          Автомат доступен{' '}
          {openingHours({
            start: point.location_start_daytime,
            end: point.location_end_daytime,
          })}
        </p>
        {point.location_id ? <DepositInfo locationId={point.location_id} /> : null}
        <hr />
        <div className="mb-8">
          <span className="title textGreen count">{countTypeAvailable}</span>
          <span className="subtitle">{`${capitalize(decOfNum(countTypeAvailable))} можно взять`}</span>
        </div>
        <div>
          <span className="title textGray-40 count">{emptyCount}</span>
          <span className="subtitle">{`${capitalize(decOfNum(emptyCount))} можно вернуть`}</span>
        </div>
        <hr />
        <UiButton className="btn_widthFull mt-4" link={getCharging ? getChargingLink : ''} onClick={onCloseModal}>
          {getCharging ? 'Взять зарядник' : 'Вернуться на карту'}
        </UiButton>
      </Popup>
    );
  }
}
