/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * GetEnergy
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url';
import * as portableFetch from 'portable-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'http://api.getenergy24.ru/v1'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration!: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name!: 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface GeApplepayData
 */
export interface GeApplepayData {
  /**
   *
   * @type {string}
   * @memberof GeApplepayData
   */
  validationUrl?: string;
}

/**
 *
 * @export
 * @interface GeBody
 */
export interface GeBody {
  /**
   *
   * @type {number}
   * @memberof GeBody
   */
  station_short_number?: number;
}

/**
 *
 * @export
 * @interface GeBody1
 */
export interface GeBody1 {
  /**
   *
   * @type {number}
   * @memberof GeBody1
   */
  session_id?: number;
}

/**
 *
 * @export
 * @interface GeBody2
 */
export interface GeBody2 {
  /**
   *
   * @type {number}
   * @memberof GeBody2
   */
  session_id?: number;
}

/**
 *
 * @export
 * @interface GeCardInfo
 */
export interface GeCardInfo {
  /**
   *
   * @type {string}
   * @memberof GeCardInfo
   */
  card_cryptogram_packet?: string;
  /**
   *
   * @type {string}
   * @memberof GeCardInfo
   */
  cardholder_name?: string;
  /**
   *
   * @type {string}
   * @memberof GeCardInfo
   */
  payment_method?: string;
}

/**
 *
 * @export
 * @interface GeCheck
 */
export interface GeCheck {
  /**
   *
   * @type {number}
   * @memberof GeCheck
   */
  station_short_number?: number;
  /**
   *
   * @type {string}
   * @memberof GeCheck
   */
  location_name?: string;
  /**
   *
   * @type {string}
   * @memberof GeCheck
   */
  location_address?: string;
  /**
   *
   * @type {string}
   * @memberof GeCheck
   */
  description1?: string;
  /**
   *
   * @type {string}
   * @memberof GeCheck
   */
  description2?: string;
  /**
   *
   * @type {GeCheckDeposit}
   * @memberof GeCheck
   */
  deposit?: GeCheckDeposit;
}

/**
 * Данные по обеспечительному платежу (депозите) по сессии. Может быть null
 * @export
 * @interface GeCheckDeposit
 */
export interface GeCheckDeposit {
  /**
   * Сумма депозита
   * @type {number}
   * @memberof GeCheckDeposit
   */
  amount?: number;
  /**
   * Оплаченая часть депозита
   * @type {number}
   * @memberof GeCheckDeposit
   */
  paid?: number;
  /**
   * Вовзращенная часть депозита
   * @type {number}
   * @memberof GeCheckDeposit
   */
  returned?: number;
  /**
   *
   * @type {GeCurrency}
   * @memberof GeCheckDeposit
   */
  currency?: GeCurrency;
  /**
   * Символ валюты депозита
   * @type {string}
   * @memberof GeCheckDeposit
   */
  currencySymbol?: string;
}

/**
 * Код валюты
 * @export
 * @enum {string}
 */
export enum GeCurrency {
  RUB = <any>'RUB',
  AZN = <any>'AZN',
  KZT = <any>'KZT',
  UZS = <any>'UZS',
  USD = <any>'USD',
  BRL = <any>'BRL',
  AED = <any>'AED',
}

/**
 *
 * @export
 * @interface GeDe
 */
export interface GeDe {
  /**
   *
   * @type {number}
   * @memberof GeDe
   */
  dtype?: number;
  /**
   *
   * @type {number}
   * @memberof GeDe
   */
  dtype_count?: number;
}

/**
 *
 * @export
 * @interface GeDebtPaymentSuccess
 */
export interface GeDebtPaymentSuccess {
  /**
   *
   * @type {number}
   * @memberof GeDebtPaymentSuccess
   */
  error_code?: number;
  /**
   *
   * @type {string}
   * @memberof GeDebtPaymentSuccess
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof GeDebtPaymentSuccess
   */
  seconds_left?: number;
}

/**
 *
 * @export
 * @interface GeInlineResponse200
 */
export interface GeInlineResponse200 {
  /**
   *
   * @type {number}
   * @memberof GeInlineResponse200
   */
  session_id?: number;
}

/**
 *
 * @export
 * @interface GeLastsession
 */
export interface GeLastsession {
  /**
   *
   * @type {string}
   * @memberof GeLastsession
   */
  session_start_time?: string;
  /**
   *
   * @type {number}
   * @memberof GeLastsession
   */
  session_duration?: number;
  /**
   *
   * @type {number}
   * @memberof GeLastsession
   */
  paid_suc?: number;
  /**
   *
   * @type {number}
   * @memberof GeLastsession
   */
  paid_unsuc?: number;
  /**
   *
   * @type {GeCheckDeposit}
   * @memberof GeLastsession
   */
  deposit?: GeCheckDeposit;
}

/**
 *
 * @export
 * @interface GeMap
 */
export interface GeMap {
  /**
   *
   * @type {number}
   * @memberof GeMap
   */
  location_id?: number;
  /**
   *
   * @type {string}
   * @memberof GeMap
   */
  location_name?: string;
  /**
   *
   * @type {string}
   * @memberof GeMap
   */
  location_address?: string;
  /**
   *
   * @type {number}
   * @memberof GeMap
   */
  location_latitude?: number;
  /**
   *
   * @type {number}
   * @memberof GeMap
   */
  location_longitude?: number;
  /**
   *
   * @type {string}
   * @memberof GeMap
   */
  location_start_daytime?: string;
  /**
   *
   * @type {string}
   * @memberof GeMap
   */
  location_end_daytime?: string;
  /**
   *
   * @type {string}
   * @memberof GeMap
   */
  location_guide?: string;
  /**
   *
   * @type {number}
   * @memberof GeMap
   */
  empty_count?: number;
  /**
   *
   * @type {Array<GeDe>}
   * @memberof GeMap
   */
  dev?: Array<GeDe>;
  /**
   *
   * @type {boolean}
   * @memberof GeMap
   */
  active?: boolean;
}

/**
 *
 * @export
 * @interface GeMapLocationDetails
 */
export interface GeMapLocationDetails {
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetails
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetails
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetails
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  start_daytime?: string;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  end_daytime?: string;
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetails
   */
  work_duration?: number;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  guide?: string;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetails
   */
  station_model?: string;
  /**
   *
   * @type {boolean}
   * @memberof GeMapLocationDetails
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetails
   */
  empty_count?: number;
  /**
   *
   * @type {{ [key: string]: GeMapLocationDetailsTariffDescriptions; }}
   * @memberof GeMapLocationDetails
   */
  tariff_descriptions?: { [key: string]: GeMapLocationDetailsTariffDescriptions };
  /**
   *
   * @type {GeMapLocationDetailsDeposit}
   * @memberof GeMapLocationDetails
   */
  deposit?: GeMapLocationDetailsDeposit;
  /**
   *
   * @type {Array<GeMapLocationDetailsPhoto>}
   * @memberof GeMapLocationDetails
   */
  photo?: Array<GeMapLocationDetailsPhoto>;
  /**
   *
   * @type {Array<GeMapLocationDetailsDev>}
   * @memberof GeMapLocationDetails
   */
  dev?: Array<GeMapLocationDetailsDev>;
}

/**
 * Данные по обеспечительному платежу (депозите) при аренде устройства в выбранной станции. Может быть null
 * @export
 * @interface GeMapLocationDetailsDeposit
 */
export interface GeMapLocationDetailsDeposit {
  /**
   * Сумма депозита
   * @type {number}
   * @memberof GeMapLocationDetailsDeposit
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetailsDeposit
   */
  currency?: string;
  /**
   * Символ валюты депозита
   * @type {string}
   * @memberof GeMapLocationDetailsDeposit
   */
  currencySymbol?: string;
  /**
   * Описание депозита
   * @type {string}
   * @memberof GeMapLocationDetailsDeposit
   */
  description?: string;
}

/**
 *
 * @export
 * @interface GeMapLocationDetailsDev
 */
export interface GeMapLocationDetailsDev {
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetailsDev
   */
  dtype?: number;
  /**
   *
   * @type {number}
   * @memberof GeMapLocationDetailsDev
   */
  dtype_count?: number;
}

/**
 *
 * @export
 * @interface GeMapLocationDetailsPhoto
 */
export interface GeMapLocationDetailsPhoto {
  /**
   * Link to download file
   * @type {string}
   * @memberof GeMapLocationDetailsPhoto
   */
  src?: string;
}

/**
 *
 * @export
 * @interface GeMapLocationDetailsTariffDescriptions
 */
export interface GeMapLocationDetailsTariffDescriptions {
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetailsTariffDescriptions
   */
  description1?: string;
  /**
   *
   * @type {string}
   * @memberof GeMapLocationDetailsTariffDescriptions
   */
  description2?: string;
}

/**
 *
 * @export
 * @interface GeMobileGetDevice
 */
export interface GeMobileGetDevice {
  /**
   *
   * @type {number}
   * @memberof GeMobileGetDevice
   */
  station_short_number?: number;
  /**
   *
   * @type {number}
   * @memberof GeMobileGetDevice
   */
  dtype?: number;
}

/**
 *
 * @export
 * @interface GeSession
 */
export interface GeSession {
  /**
   *
   * @type {number}
   * @memberof GeSession
   */
  session_id?: number;
  /**
   *
   * @type {string}
   * @memberof GeSession
   */
  session_status?: string;
  /**
   *
   * @type {string}
   * @memberof GeSession
   */
  session_start_time?: string;
  /**
   *
   * @type {number}
   * @memberof GeSession
   */
  time_in_use?: number;
  /**
   *
   * @type {string}
   * @memberof GeSession
   */
  tariff?: string;
  /**
   *
   * @type {number}
   * @memberof GeSession
   */
  paid_suc?: number;
  /**
   *
   * @type {number}
   * @memberof GeSession
   */
  paid_unsuc?: number;
  /**
   *
   * @type {number}
   * @memberof GeSession
   */
  amount_to_pay?: number;
  /**
   *
   * @type {string}
   * @memberof GeSession
   */
  time_to_pay?: string;
  /**
   *
   * @type {string}
   * @memberof GeSession
   */
  pb_return_time?: string;
  /**
   *
   * @type {GeCheckDeposit}
   * @memberof GeSession
   */
  deposit?: GeCheckDeposit;
}

/**
 *
 * @export
 * @interface GeSessionMobile
 */
export interface GeSessionMobile {
  /**
   *
   * @type {number}
   * @memberof GeSessionMobile
   */
  session_id?: number;
  /**
   *
   * @type {string}
   * @memberof GeSessionMobile
   */
  session_status?: string;
}

/**
 *
 * @export
 * @interface GeUserMobile
 */
export interface GeUserMobile {
  /**
   *
   * @type {string}
   * @memberof GeUserMobile
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GeUserMobile
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof GeUserMobile
   */
  dtype?: number;
}

/**
 *
 * @export
 * @interface GeUserPaymentMethod
 */
export interface GeUserPaymentMethod {
  holder: string;
  id: null;
  is_default: boolean;
  number: string;
  payment_method: string;
  payment_system: string;
  payment_type: string;
}
/**
 *
 * @export
 * @interface GeUserMobileGet
 */
export interface GeUserMobileGet {
  /**
   *
   * @type {string}
   * @memberof GeUserMobileGet
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GeUserMobileGet
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof GeUserMobileGet
   */
  dtype?: number;
  /**
   *
   * @type {string}
   * @memberof GeUserMobileGet
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof GeUserMobileGet
   */
  payment_methods: GeUserPaymentMethod[];
}

/**
 * _Api - fetch parameter creator
 * @export
 */
export const _ApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Старт сессии ApplePay
     * @param {GeApplepayData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applepaysession(body: GeApplepayData, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling applepaysession.');
      }
      const localVarPath = `/mobile/applepaysession`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeApplepayData' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Привязать карту
     * @param {GeCardInfo} body Данные карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAttachCp(body: GeCardInfo, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling cardAttachCp.');
      }
      const localVarPath = `/mobile/card_attach_cp`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeCardInfo' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отвязать карту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardDetachCp(options: any = {}): FetchArgs {
      const localVarPath = `/mobile/card_detach_cp`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Оплатить долги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtpayment(options: any = {}): FetchArgs {
      const localVarPath = `/mobile/debtpayment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all location info
     * @param {number} location_id Location id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceDetails(location_id: number, options: any = {}): FetchArgs {
      // verify required parameter 'location_id' is not null or undefined
      if (location_id === null || location_id === undefined) {
        throw new RequiredError(
          'location_id',
          'Required parameter location_id was null or undefined when calling getDeviceDetails.',
        );
      }
      const localVarPath = `/mobile/map/location/{location_id}`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(location_id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список доступных павербанков для карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevicesMap(options: any = {}): FetchArgs {
      const localVarPath = `/mobile/map`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody2} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastsession(body: GeBody2, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling lastsession.');
      }
      const localVarPath = `/mobile/lastsession`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeBody2' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Проверка
     * @param {GeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileCheck(body: GeBody, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling mobileCheck.');
      }
      const localVarPath = `/mobile/check`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeBody' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновить профиль
     * @param {GeUserMobile} body Параметры юзера
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileEditUser(body: GeUserMobile, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling mobileEditUser.');
      }
      const localVarPath = `/mobile/editprofile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeUserMobile' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Параметры активной сессии
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetActiveSession(options: any = {}): FetchArgs {
      const localVarPath = `/mobile/getactivesession`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Запрос на получение павербанка
     * @param {GeMobileGetDevice} body Параметры станции
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetDevice(body: GeMobileGetDevice, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling mobileGetDevice.');
      }
      const localVarPath = `/mobile/getdevice`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeMobileGetDevice' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody1} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetSession(body: GeBody1, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling mobileGetSession.');
      }
      const localVarPath = `/mobile/getsession`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GeBody1' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Профиль по токену
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetUser(options: any = {}): FetchArgs {
      const localVarPath = `/mobile/getprofile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * _Api - functional programming interface
 * @export
 */
export const _ApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Старт сессии ApplePay
     * @param {GeApplepayData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applepaysession(body: GeApplepayData, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).applepaysession(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Привязать карту
     * @param {GeCardInfo} body Данные карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAttachCp(body: GeCardInfo, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).cardAttachCp(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Отвязать карту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardDetachCp(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).cardDetachCp(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Оплатить долги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtpayment(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GeDebtPaymentSuccess> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).debtpayment(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Returns all location info
     * @param {number} location_id Location id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceDetails(
      location_id: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GeMapLocationDetails> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).getDeviceDetails(location_id, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Список доступных павербанков для карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevicesMap(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeMap>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).getDevicesMap(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody2} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastsession(body: GeBody2, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeLastsession>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).lastsession(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Проверка
     * @param {GeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileCheck(body: GeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeCheck>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileCheck(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Обновить профиль
     * @param {GeUserMobile} body Параметры юзера
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileEditUser(body: GeUserMobile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileEditUser(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Параметры активной сессии
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetActiveSession(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeSession>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileGetActiveSession(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Запрос на получение павербанка
     * @param {GeMobileGetDevice} body Параметры станции
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetDevice(
      body: GeMobileGetDevice,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeInlineResponse200>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileGetDevice(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody1} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetSession(
      body: GeBody1,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeSessionMobile>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileGetSession(body, options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Профиль по токену
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GeUserMobileGet>> {
      const localVarFetchArgs = _ApiFetchParamCreator(configuration).mobileGetUser(options);
      return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * _Api - factory interface
 * @export
 */
export const _ApiFactory = function(configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Старт сессии ApplePay
     * @param {GeApplepayData} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applepaysession(body: GeApplepayData, options?: any) {
      return _ApiFp(configuration).applepaysession(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Привязать карту
     * @param {GeCardInfo} body Данные карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardAttachCp(body: GeCardInfo, options?: any) {
      return _ApiFp(configuration).cardAttachCp(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Отвязать карту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cardDetachCp(options?: any) {
      return _ApiFp(configuration).cardDetachCp(options)(fetch, basePath);
    },
    /**
     *
     * @summary Оплатить долги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtpayment(options?: any) {
      return _ApiFp(configuration).debtpayment(options)(fetch, basePath);
    },
    /**
     *
     * @summary Returns all location info
     * @param {number} location_id Location id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceDetails(location_id: number, options?: any) {
      return _ApiFp(configuration).getDeviceDetails(location_id, options)(fetch, basePath);
    },
    /**
     *
     * @summary Список доступных павербанков для карты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevicesMap(options?: any) {
      return _ApiFp(configuration).getDevicesMap(options)(fetch, basePath);
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody2} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastsession(body: GeBody2, options?: any) {
      return _ApiFp(configuration).lastsession(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Проверка
     * @param {GeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileCheck(body: GeBody, options?: any) {
      return _ApiFp(configuration).mobileCheck(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Обновить профиль
     * @param {GeUserMobile} body Параметры юзера
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileEditUser(body: GeUserMobile, options?: any) {
      return _ApiFp(configuration).mobileEditUser(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Параметры активной сессии
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetActiveSession(options?: any) {
      return _ApiFp(configuration).mobileGetActiveSession(options)(fetch, basePath);
    },
    /**
     *
     * @summary Запрос на получение павербанка
     * @param {GeMobileGetDevice} body Параметры станции
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetDevice(body: GeMobileGetDevice, options?: any) {
      return _ApiFp(configuration).mobileGetDevice(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Статус сессии по session_id
     * @param {GeBody1} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetSession(body: GeBody1, options?: any) {
      return _ApiFp(configuration).mobileGetSession(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Профиль по токену
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileGetUser(options?: any) {
      return _ApiFp(configuration).mobileGetUser(options)(fetch, basePath);
    },
  };
};

/**
 * _Api - object-oriented interface
 * @export
 * @class _Api
 * @extends {BaseAPI}
 */
export class _Api extends BaseAPI {
  /**
   *
   * @summary Старт сессии ApplePay
   * @param {GeApplepayData} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public applepaysession(body: GeApplepayData, options?: any) {
    return _ApiFp(this.configuration).applepaysession(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Привязать карту
   * @param {GeCardInfo} body Данные карты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public cardAttachCp(body: GeCardInfo, options?: any) {
    return _ApiFp(this.configuration).cardAttachCp(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Отвязать карту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public cardDetachCp(options?: any) {
    return _ApiFp(this.configuration).cardDetachCp(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Оплатить долги
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public debtpayment(options?: any) {
    return _ApiFp(this.configuration).debtpayment(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Returns all location info
   * @param {number} location_id Location id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public getDeviceDetails(location_id: number, options?: any) {
    return _ApiFp(this.configuration).getDeviceDetails(location_id, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Список доступных павербанков для карты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public getDevicesMap(options?: any) {
    return _ApiFp(this.configuration).getDevicesMap(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Статус сессии по session_id
   * @param {GeBody2} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public lastsession(body: GeBody2, options?: any) {
    return _ApiFp(this.configuration).lastsession(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Проверка
   * @param {GeBody} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileCheck(body: GeBody, options?: any) {
    return _ApiFp(this.configuration).mobileCheck(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Обновить профиль
   * @param {GeUserMobile} body Параметры юзера
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileEditUser(body: GeUserMobile, options?: any) {
    return _ApiFp(this.configuration).mobileEditUser(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Параметры активной сессии
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileGetActiveSession(options?: any) {
    return _ApiFp(this.configuration).mobileGetActiveSession(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Запрос на получение павербанка
   * @param {GeMobileGetDevice} body Параметры станции
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileGetDevice(body: GeMobileGetDevice, options?: any) {
    return _ApiFp(this.configuration).mobileGetDevice(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Статус сессии по session_id
   * @param {GeBody1} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileGetSession(body: GeBody1, options?: any) {
    return _ApiFp(this.configuration).mobileGetSession(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Профиль по токену
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof _Api
   */
  public mobileGetUser(options?: any) {
    return _ApiFp(this.configuration).mobileGetUser(options)(this.fetch, this.basePath);
  }
}
