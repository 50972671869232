import React, { useEffect, useState } from 'react';

import { Map, InfoCardSession, InfoCard, PlaceInfoPopup, Timer } from '../../components';
import { UiButton } from '../../ui';
import { inject, observer } from 'mobx-react';
import { api, IStoreMain, store } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { GeMap, GeDebtPaymentSuccess } from '../../store/generated-api';
import { countdown as countdownTick, setIntervalAndExecute } from '../../utils';
import { DebtPaymentStatus } from '../../types/PaymentStatus';

type DebtPaymentCardProps = {
  show: boolean;
  status?: number;
  amount?: number;
  message?: string;
  timer?: number;
  onUpdatePaymentMethod(): void;
  onTryAgain?: () => void;
  onClose?: () => void;
};
const DebtPaymentCard = ({
  show,
  status,
  amount,
  message,
  timer,
  onUpdatePaymentMethod,
  onTryAgain,
  onClose,
}: DebtPaymentCardProps) => {
  const [loading, setLoading] = useState(false);
  const handleTryAgain = () => {
    if (!onTryAgain) return;
    setLoading(true);
    onTryAgain();
  };

  useEffect(() => {
    setLoading(false);
  }, [amount, status]);

  const [countdown, setCountdown] = useState({
    time: Date.now(),
    duration: timer ? timer / 60 : 0,
    complete: false,
  });
  const handleCountdownEnd = () => setCountdown(countdown => ({ ...countdown, complete: true }));
  useEffect(() => {
    setCountdown({ time: Date.now(), duration: timer ? timer / 60 : 0, complete: false });
  }, [timer, status, amount]);

  const isDebt = amount || status === 1 || status === 2 || status === 3 || status === 5;

  const btns = [];

  if (isDebt) {
    if (!loading && (!timer || countdown.complete)) {
      btns.push({ title: 'Попробовать еще раз', onClick: handleTryAgain });
    }
    btns.push({ title: 'Указать другую карту', onClick: onUpdatePaymentMethod });
  } else {
    btns.push({ title: 'Закрыть', onClick: onClose });
  }

  return (
    <InfoCard.Item
      theme={isDebt ? 'bgRed' : 'bgGreen'}
      title="Проблемы с оплатой"
      show={show}
      minimized={false}
      content={
        isDebt ? (
          <>
            Нам не удалось списать с вашей карты оплату {amount ? `${amount} рублей` : ''} за суточный тариф
            использования зарядника Get Energy.
            {message && !countdown.complete && (
              <>
                <br />
                <br />
                {timer ? (
                  <>
                    Оплата не удалась.
                    <br />
                    Попробуйте ещё раз через{' '}
                    <Timer
                      fn={countdownTick}
                      value={countdown.time}
                      duration={countdown.duration}
                      cb={handleCountdownEnd}
                    />{' '}
                    сек
                  </>
                ) : (
                  message
                )}
              </>
            )}
            {loading && (
              <>
                <div className="spinner _small _white">
                  <div />
                  <div />
                  <div />
                </div>
                <br />
              </>
            )}
          </>
        ) : (
          <>{message}</>
        )
      }
      btns={btns}
      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
    />
  );
};

interface Props {}

@inject('store')
@observer
export class Main extends React.Component<Props & IStoreMain & RouteComponentProps> {
  state = {
    currentPoint: {} as GeMap,
    location: this.props.store.map.location,
    showPlacePopup: false,
    debtPayment: undefined as GeDebtPaymentSuccess | undefined,
  };

  private interval: number = 0;
  private locationInterval: number = 0;

  componentDidMount() {
    const { map, user } = this.props.store!;

    this.interval = setIntervalAndExecute(map.getPoints, 1000 * 60, true);
    this.locationInterval = setIntervalAndExecute(this.handleGeolocation, 1000 * 5, true);
    if (user.isAuth) this.handleDebtPayment(true);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
    window.clearInterval(this.locationInterval);
  }

  handleDebtPayment = (skipNoDebt?: boolean) => {
    api.client
      .debtpayment(this.props.store!.headerToken)
      .then(debtPayment => this.setState({ debtPayment }))
      .catch(async res => {
        const debtPayment = await res.json();
        if (debtPayment && [DebtPaymentStatus.TIMEOUT, DebtPaymentStatus.NO_DEBT].includes(debtPayment.error_code)) {
          this.setState({ debtPayment });
        }
        return;
      });
  };

  renderFooter = () => {
    const { session, profile } = this.props.store;
    const { debtPayment } = this.state;

    return (
      <>
        <InfoCard closeHelpers={session.showHelper && session.helperSwitch}>
          <InfoCard.Item
            title="Отлично!"
            theme="bgGreen"
            typeCard="helper"
            show={session.sessionActive && session.showHelper === 'get'}
            injectedProps={{
              list: [
                { title: 'Возьмите зарядник из мигающей ячейки автомата' },
                { title: 'Вернуть зарядник легко — просто вставьте его в свободную ячейку любого автомата' },
              ],
              type: 'iconed',
            }}
          />
          <InfoCard.Item
            title="Как вернуть зарядник"
            typeCard="helper"
            show={session.sessionActive && session.showHelper === 'return'}
          />
          <DebtPaymentCard
            show={session.sessionActive && session.paidFail}
            amount={session.info && session.info.paid_unsuc}
            status={debtPayment && debtPayment.error_code}
            message={debtPayment && debtPayment.message}
            timer={debtPayment && debtPayment.seconds_left}
            onUpdatePaymentMethod={() => this.props.history.push('/payment-method/edit')}
            onTryAgain={() => this.handleDebtPayment()}
            onClose={() => this.setState({ debtPayment: undefined })}
          />
          <InfoCard.Item
            theme="bgGreen"
            title="Заполните профиль"
            show={session.sessionActive && !profile.fullInfo}
            content={
              <>
                Чтоб мы знали куда вам отправлять счета об оплате.
                <br />
                Мы не будем вам отправлять ничего, кроме счетов.
              </>
            }
            btns={{
              title: 'Заполнить',
              link: '/profile',
            }}
          />
          <InfoCard.Item
            title={session.sessionActive ? 'Текущая сессия' : 'Вы вернули зарядник'}
            typeCard="session"
            show={session.sessionActive || session.showHelper === 'closed'}
            component={InfoCardSession}
            injectedProps={{
              closed: !session.sessionActive,
              info: session.info,
              onShowHelper: session.helperSwitch,
            }}
          />
        </InfoCard>
        {!session.sessionActive && (
          <>
            {debtPayment ? (
              <DebtPaymentCard
                show
                status={debtPayment!.error_code}
                message={debtPayment!.message}
                timer={debtPayment!.seconds_left}
                onUpdatePaymentMethod={() => this.props.history.push('/payment-method/edit')}
                onTryAgain={() => this.handleDebtPayment()}
                onClose={() => this.setState({ debtPayment: undefined })}
              />
            ) : (
              <UiButton className="btn_widthFull btn_hasShadow bMap__btn" link="/get-charging">
                Взять зарядник
              </UiButton>
            )}
          </>
        )}
      </>
    );
  };

  handleGeolocation = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const location = { lat: coords.latitude, lng: coords.longitude };
      store.map.setLocation(location);
      this.setState({ location });
    });
  };

  handleClick = (point: GeMap) => {
    this.setState({ currentPoint: point, showPlacePopup: true });
  };

  handleClosePopup = () => this.setState({ showPlacePopup: false });

  render() {
    const { store } = this.props;
    const { showPlacePopup, currentPoint, location } = this.state;
    return (
      <div className="bMap">
        <Map
          location={location}
          points={Array.from(store!.map.points) || []}
          onGeolocation={this.handleGeolocation}
          onPointClick={this.handleClick}
        />
        <PlaceInfoPopup show={showPlacePopup} point={currentPoint} onCloseModal={this.handleClosePopup} />
        <div className="bMap__content">
          <div className="container_sm bMap__contentContainer">
            {store.user.isAuth ? (
              this.renderFooter()
            ) : (
              <UiButton className="btn_widthFull btn_hasShadow bMap__btn" link="/sign-in">
                Войти
              </UiButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}
