import { types, Instance, flow, getParent } from 'mobx-state-tree';
import { api, appStorage } from '..';
import { IStore } from './root.store';
import { inZone } from '../../utils/in-zone';

export const Map = types
  .model({
    location: types.maybeNull(types.model({ lat: types.number, lng: types.number })),
    points: types.array(types.frozen()),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get root(): any {
      return getParent(self);
    },
    // get filteredPoint() {
    //   const root: IStore = getParent(self);
    //   const dtype = Number(root.user.chargingType);
    //   return filter(self.points, { dev: [{ dtype }] }) as GeMap[];
    // },
  }))
  .actions(self => ({
    setLocation(location: { lat: number; lng: number }) {
      appStorage.save('location', JSON.stringify(location));
      self.location = location;
    },

    getPoints: flow(function* getPoints() {
      self.loading = true;
      try {
        const location = self.location;
        const resp = yield api.client.getDevicesMap({
          headers: {
            sc: inZone({
              latitude: location ? location.lat : undefined,
              longitude: location ? location.lng : undefined,
            }),
          },
        });
        self.points = resp;
      } catch (err) {
        console.error('Failed request ', err);
      }
      self.loading = false;
    }),
  }));

export type IMap = Instance<typeof Map>;
